import { useEffect, useState } from "react";
import Top from "../Top";
import erc20_Abi from "../../abi/erc20.json";
import swap_Abi from "../../abi/SwapForActive.json";
import Addr from "../../addr/address.json";
import styles from "./index.module.css";
import BigNumber from "bignumber.js";
import { Button, message, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";
import Carousel from "../component/Carousel";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { addressState } from "../../store";

export default function Dapp({ provider }) {
  let times = "Sat Mar 31 2024 13:00:00 GMT+0800";

  const [usdtNum, setUsdtNum] = useState(0);
  const [aktNum, setAktNum] = useState(0);
  const address = useRecoilValue(addressState);
  const [calculateNum, setCalculateNum] = useState(0);
  const [btn, setBtn] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const akt_addr = Addr.AKT;
  const usdt_addr = Addr.usdt;
  const swap_addr = Addr.SwapForActive;
  const usdt = new ethers.Contract(usdt_addr, erc20_Abi, provider);
  const usdtSign = new ethers.Contract(
    usdt_addr,
    erc20_Abi,
    provider.getSigner()
  );
  const akt = new ethers.Contract(akt_addr, erc20_Abi, provider);
  const swap = new ethers.Contract(swap_addr, swap_Abi, provider);
  const swapSign = new ethers.Contract(
    swap_addr,
    swap_Abi,
    provider.getSigner()
  );
  const initData = () => {
    usdt.balanceOf(address).then((res) => {
      setUsdtNum((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
    akt.balanceOf(address).then((res) => {
      setAktNum((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
  };
  const approveUSDT = () => {
    usdt.allowance(address, swap_addr).then((res) => {
      if (ethers.utils.formatEther(res) * 1 >= 10) {
        setIsApproved(true);
      } else {
        setIsApproved(false);
      }
    });
  };
  const active = async () => {
    setBtn(true);
    if (!isApproved) {
      let defaultNum = ethers.utils.parseEther(usdtNum + "");
      try {
        const receipt = await (
          await usdtSign.approve(swap_addr, defaultNum)
        ).wait();
        if (receipt.status === 1) {
          message.success("授权成功");
          approveUSDT();
          setBtn(false);
        }
      } catch (e) {
        message.error("授权失败");
        setBtn(false);
        message.error(e.reason || e.data?.message || e.message);
      }
    } else {
      try {
        let receipt = await (await swapSign.active()).wait();
        if (receipt.status === 1) {
          message.success("购买成功");
          approveUSDT();
          setBtn(false);
        }
      } catch (e) {
        message.error("购买失败");
        setBtn(false);
        message.error(e.reason || e.data?.message || e.message);
      }
    }
  };

  const calculateAKTCanSwap = () => {
    swap
      .calculateAKTCanSwap()
      .then((res) =>
        setCalculateNum((ethers.utils.formatEther(res) * 1).toFixed(4))
      );
  };

  useEffect(() => {
    if (address === "") return;
    // initData();
    // approveUSDT();
    // calculateAKTCanSwap();
  }, [address]);

  return (
    <>
     
    </>
  );
}
