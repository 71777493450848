import { useRecoilValue } from "recoil";
import { addressState } from "../../store";
import { Button, message, ConfigProvider } from "antd";
import copy from "copy-to-clipboard";
import styles from "./index.module.css";
import relation_Abi from "../../abi/Relation.json";
import Addr from "../../addr/address.json";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";
import { useState } from "react";
const InvBar = ({ inviter, provider }) => {
  const address = useRecoilValue(addressState);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const relation_addr = Addr.Relation;
  const bindRelation = async () => {
    setLoading(true);

    let refferrer = inviter;
    if (inviter === "") {
      new ethers.Contract(relation_addr, relation_Abi, provider)
        .defaultReferrer()
        .then((res) => (refferrer = res));
    }

    const relationKpi = new ethers.Contract(
      relation_addr,
      relation_Abi,
      provider.getSigner()
    );
    try {
      const res = await (
        await relationKpi.bindRelationship(address, refferrer)
      ).wait();
      if (res.status === 0) {
        setLoading(false);
        message.info("绑定上级成功");
      }
    } catch (err) {
      setLoading(false);
      message.error(err.data?.message || err.message);
    }
  };
  const copyInvote = () => {
    if (address === "") {
      message.info(t("conn"));
      return;
    }
    copy(window.location.href.split("?", 1).toString() + "?inviter=" + address);
    message.success(t("copys"));
  };

  return (
    <>
      {/* <img src="./img/home/bodytop.png" alt="" loading="lazy" /> */}
      <div className="w-full">
        <div className={styles.font1} style={{ marginLeft: "16px" }}>
          {t("myshare")}
        </div>
        <div className={styles.hometopbar}>
          <div className={styles.urlbg}>
            {window.location.href.split("?", 1).toString() +
              "?inviter=" +
              address.slice(0, 4) +
              "..." +
              address.slice(-4)}
          </div>
          <div className={styles.centerbt}>
            <Button
              className={"homebtnbuy"}
              onClick={copyInvote}
              loading={loading}
            >
              {t("copylink")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvBar;
