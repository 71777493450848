import { ethers } from "ethers";

import styles from "./Top.module.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cm from "classnames";
import cookie from "react-cookies";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { addressState } from "../store";
export default function Top({ provider }) {
  const { t, i18n } = useTranslation();
  const [lug, setLug] = useState(
    cookie.load("CE") === undefined ? "en" : cookie.load("CE")
  );
  const [lshow, setLshow] = useState(false);
  const address = useRecoilValue(addressState);
  const setAddress = useSetRecoilState(addressState);
  const [isScroll, setIsScroll] = useState(false);
  useEffect(() => {
    i18n.changeLanguage(lug);
    connect();
    conBag();
  }, []);

  const conBag = async () => {
    if (provider !== undefined && address === "") {
      connect();
    } else {
      setAddress("");
      disconnect();
    }
  };

  async function connect() {
    try {
      const accounts = await provider.send("eth_requestAccounts", []);
      setAddress(accounts[0]);
      //  window.location.reload()
    } catch (e) {}
  }

  async function disconnect() {
    try {
      await provider.send("eth_requestAccounts", [{ eth_accounts: {} }]);
    } catch (e) {}
  }

  const chLug = (value) => {
    i18n.changeLanguage(value);
    cookie.save("CE", value, { path: "/" });
    setLug(value);
  };

  useEffect(() => {
    function handleScroll() {
      const scrollTops = document.documentElement.scrollTop;
      if (scrollTops >= 30) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className={cm(styles.top, isScroll ? styles.black : "")}>
        <div className={styles.d1}>MM.Fun</div>
        <div className={styles.d0}>
          <div>
            <div className={styles.bt0} onClick={conBag}>
              <div className={styles.bt2in1}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3334 11.6667C13.3334 12.357 13.893 12.9167 14.5834 12.9167C15.2737 12.9167 15.8334 12.357 15.8334 11.6667C15.8334 10.9764 15.2737 10.4167 14.5834 10.4167C13.893 10.4167 13.3334 10.9764 13.3334 11.6667Z"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M8.33329 5.83333H13.3333C15.6903 5.83333 16.8688 5.83333 17.601 6.56557C18.3333 7.2978 18.3333 8.47633 18.3333 10.8333V12.5C18.3333 14.857 18.3333 16.0355 17.601 16.7677C16.8688 17.5 15.6903 17.5 13.3333 17.5H8.33329C5.19059 17.5 3.61925 17.5 2.64293 16.5237C1.66663 15.5474 1.66663 13.976 1.66663 10.8333V9.16667C1.66663 6.02397 1.66663 4.45262 2.64293 3.47631C3.61925 2.5 5.19059 2.5 8.33329 2.5H11.6666C12.4416 2.5 12.8291 2.5 13.147 2.58518C14.0097 2.81635 14.6836 3.49022 14.9148 4.35295C15 4.67087 15 5.05836 15 5.83333"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                &nbsp;
                {address === "" || address === null || address === undefined
                  ? t("disconnect")
                  : address.toString().slice(0, 4) +
                    "..." +
                    address.toString().slice(-4)}
              </div>
            </div>
          </div>
          &nbsp;&nbsp;
          <div className="center">
            {lug === "en" ? (
              <div
                onClick={() => chLug("zh")}
                className="center"
                style={{ width: "40px", height: "40px" }}
              >
                <div className={styles.language}>
                  {i18n.language.toUpperCase()}
                </div>
                {/* <img
                  src="./img/language/en.png"
                  alt="zh"
                  style={{ width: "40px", height: "40px" }}
                /> */}
              </div>
            ) : (
              <div
                onClick={() => chLug("en")}
                className="center"
                style={{ width: "40px", height: "40px" }}
              >
                <div className={styles.language}>
                  {i18n.language.toUpperCase()}
                </div>
                {/* <img src="./img/language/zh.png" alt="En" /> */}
              </div>
            )}
          </div>
          <div className={styles.burn}></div>
          {/* <div className="d2">
            <div>
              <div>
                {lug.toLocaleUpperCase()}
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.21891 8.02657C5.61924 8.52735 6.38076 8.52735 6.78109 8.02657L8.70143 5.62437C9.2249 4.96955 8.75868 3.99996 7.92034 3.99996H4.07966C3.24133 3.99996 2.7751 4.96955 3.29857 5.62437L5.21891 8.02657Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* {lshow ? (
        <>
          <div className={styles.showlug}>
            <div
              className={lug === "en" ? styles.showlug0 : styles.showlug1}
              onClick={() => {
                chLug("en");
                setLshow(false);
              }}
            >
              {t("en")}
            </div>
            <div
              className={lug === "zh" ? styles.showlug0 : styles.showlug1}
              onClick={() => {
                chLug("zh");
                setLshow(false);
              }}
            >
              {t("zh")}
            </div>
          </div>
        </>
      ) : (
        <></>
      )} */}
    </>
  );
}
