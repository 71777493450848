import styles from "./index.module.css";
import BigNumber from "bignumber.js";
import Top from "../Top";
import Carousel from "../component/Carousel";
import { Button, ConfigProvider, message, Progress, Select } from "antd";
import { useEffect, useState } from "react";
import miningMachine_Abi from "../../abi/MiningMachine.json";
import Addr from "../../addr/address.json";
import { useRecoilValue } from "recoil";
import { addressState } from "../../store";
import { ethers } from "ethers";
function Income({ provider }) {
  const [showOp, setShowOp] = useState(0);
  const preloadImg = () => {
    new Image().src = "./img/income/little.png";
    new Image().src = "./img/income/middle.png";
    new Image().src = "./img/income/large.png";
  };
  const miningMachine_addr = Addr.MiningMachine;
  const address = useRecoilValue(addressState);
  const [users, setUsers] = useState({
    totalPurchased: 0,
    outNumber: 0,
    totalRewarded: 0,
    accumulatedDynamic: 0,
  });
  const [staticInNo, setStaticInNo] = useState(0);
  const [staticInCan, setStaticInCan] = useState([0, 0]);
  const [dynamicIn, setDynamicIn] = useState(0);
  const [genesisBuy, setGenesisBuy] = useState([0, 0, 0]);
  const [dposBuy, setDposBuy] = useState([0, 0, 0]);
  const [btn, setBtn] = useState([false, false, false]);
  const [userStatic, setUserStatic] = useState({
    accumulatedStatic: 0,
    lastStaticTime: 0,
    staticClaimedDays: 0,
    totalRewarded: 0,
    withdrawStaticNum: 0,
  });
  const [accountNum, setAccountNum] = useState(0);
  const miningMachineSign = new ethers.Contract(
    miningMachine_addr,
    miningMachine_Abi,
    provider.getSigner()
  );

  const initData = async () => {
    const miningMachine = new ethers.Contract(
      miningMachine_addr,
      miningMachine_Abi,
      provider
    );
    miningMachine.users(address).then((res) => {
      setUsers({
        totalPurchased: (
          ethers.utils.formatEther(res.totalPurchased) * 1
        ).toFixed(4),
        outNumber: (ethers.utils.formatEther(res.outNumber) * 1).toFixed(4),
        totalRewarded: (
          ethers.utils.formatEther(res.totalRewarded) * 1
        ).toFixed(4),
        accumulatedDynamic: (
          ethers.utils.formatEther(res.accumulatedDynamic) * 1
        ).toFixed(4),
      });
    });

    miningMachine.userStatics(address).then((res) => {
      setUserStatic({
        totalRewarded: (
          ethers.utils.formatEther(res.totalRewarded) * 1
        ).toFixed(4),
        accumulatedStatic: (
          ethers.utils.formatEther(res.accumulatedStatic) * 1
        ).toFixed(4),
        lastStaticTime: (
          ethers.utils.formatEther(res.lastStaticTime) * 1
        ).toFixed(4),
        staticClaimedDays: (
          ethers.utils.formatEther(res.staticClaimedDays) * 1
        ).toFixed(4),
        withdrawStaticNum: (
          ethers.utils.formatEther(res.withdrawStaticNum) * 1
        ).toFixed(4),
      });
    });
    miningMachine
      .pendingStatic(address)
      .then((res) =>
        setStaticInNo((ethers.utils.formatEther(res) * 1).toFixed(4))
      );
    miningMachine.getStaticCanTake(address).then((res) => {
      setStaticInCan([
        (ethers.utils.formatEther(res[0]) * 1).toFixed(4),
        (ethers.utils.formatEther(res[1]) * 1).toFixed(4),
      ]);
    });
    miningMachine
      .pendingDynamic(address)
      .then((res) =>
        setDynamicIn((ethers.utils.formatEther(res) * 1).toFixed(4))
      );
    miningMachine.getGenesisBuyLog(address).then((res) => {
      setGenesisBuy([parseInt(res[0]), parseInt(res[1]), parseInt(res[2])]);
    });
    miningMachine.getDPOSBuyLog(address).then((res) => {
      setDposBuy([parseInt(res[0]), parseInt(res[1]), parseInt(res[2])]);
    });
    miningMachine.getAccountOut(address).then((res) => {
      setAccountNum((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
  };

  const release = async () => {
    setBtn([true, btn[1], btn[2]]);
    try {
      const receipt = await (await miningMachineSign.releaseStatic()).wait();
      if (receipt.status === 1) {
        message.success("释放成功");
        window.location.reload();
        setBtn([false, btn[1], btn[2]]);
      } else {
        message.error("释放失败");
        setBtn([false, btn[1], btn[2]]);
      }
    } catch (callError) {
      setBtn([false, btn[1], btn[2]]);
      message.error(
        callError.reason || callError.data?.message || callError.message
      );
    }
  };
  const withdraw = async () => {
    setBtn([btn[0], true, btn[2]]);
    try {
      const receipt = await (await miningMachineSign.withdrawStatic()).wait();
      if (receipt.status === 1) {
        message.success("提取成功");
        setBtn([btn[0], false, btn[2]]);
        window.location.reload();
      } else {
        message.error("提取失败");
        setBtn([btn[0], false, btn[2]]);
      }
    } catch (callError) {
      setBtn([btn[0], false, btn[2]]);
      message.error(
        callError.reason || callError.data?.message || callError.message
      );
    }
  };
  const withdrawDynami = async () => {
    setBtn([btn[0], btn[1], true]);
    try {
      const receipt = await (await miningMachineSign._withdrawDynamic()).wait();
      if (receipt.status === 1) {
        message.success("释放成功");
        setBtn([btn[0], btn[1], false]);
        window.location.reload();
      } else {
        message.error("释放失败");
        setBtn([btn[0], btn[1], false]);
      }
    } catch (callError) {
      setBtn([btn[0], btn[1], false]);
      message.error(
        callError.reason || callError.data?.message || callError.message
      );
    }
  };

  useEffect(() => {
    // preloadImg();
    if (address === "") return;
    // initData();
  }, [address]);
  return (
    <>
      
    </>
  );
}
export default Income;
