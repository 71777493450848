import styles from "./index.module.css";
import cm from "classnames";
import kpi_Abi from "../../abi/KPILog.json";
import relation_Abi from "../../abi/Relation.json";
import node_Abi from "../../abi/nodeRewards.json";
import preSell_Abi from "../../abi/PreSellLP.json";
import Addr from "../../addr/address.json";
import InvBar from "../component/Invbar";
import Lpdata_Abi from "../../abi/LPData.json";
import { Button, message } from "antd";
import { ethers } from "ethers";
import { useState, useEffect } from "react";

import { useRecoilValue } from "recoil";
import { addressState } from "../../store";
import { useTranslation } from "react-i18next";
export default function Invite({ provider, inviter }) {
  const [list, setList] = useState([]);
  const [userinfo, setUserinfo] = useState([0, 0]);
  const [rewardinfo, setRewardinfo] = useState([0, 0]);
  const [isNode, setIsNode] = useState(false);
  const [pendNum, setPendNum] = useState(0);
  const [subNum, setSubNum] = useState(0);
  const [btn, setBtn] = useState(false);
  const [btn1, setBtn1] = useState(false);
  const [perSellData, setPerSellData] = useState([]);
  const [computeNum, setComputeNum] = useState(0);
  const address = useRecoilValue(addressState);
  const { t } = useTranslation();
  const kpi_addr = Addr.KPILog;
  const reward_addr = Addr.NFTReward;
  const relation_addr = Addr.Relation;
  const node_addr = Addr.NodeList;
  const presalelp_addr = Addr.PreSellLP;
  const lpdata_addr = Addr.LPData;
  const kpi = new ethers.Contract(kpi_addr, kpi_Abi, provider);
  const nodeSign = new ethers.Contract(
    node_addr,
    node_Abi,
    provider.getSigner()
  );
  const perSell = new ethers.Contract(presalelp_addr, preSell_Abi, provider);
  const lpdata = new ethers.Contract(lpdata_addr, Lpdata_Abi, provider);
  const getuserInfo = async () => {
    await kpi.userInfo(address).then((res) => {
      setUserinfo({
        total: (ethers.utils.formatEther(res[0]) * 1).toFixed(4),
        amount: (ethers.utils.formatEther(res[1]) * 1).toFixed(4),
      });
    });
  };

  const getNodeInfo = () => {
    const nodelist = new ethers.Contract(node_addr, node_Abi, provider);

    nodelist.isNode(address).then((res) => {
      setIsNode(res);
    });
    nodelist.pending(address).then((res) => {
      setPendNum((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
  };

  const withdraw = async () => {
    if (pendNum === 0) {
      message.info(t("noicome"));
      return;
    }

    setBtn(true);

    try {
      let res = await nodeSign.withdraw();
      let po = res.wait();
      if (po.status === 1) {
        message.success(t("extract" + t("success")));
        setBtn(false);
        window.location.reload();
      }
    } catch (callError) {
      setBtn(false);
      message.error(
        callError.reason || callError.data?.message || callError.message
      );
      message.error(t("extract") + t("fail"));
    }
  };

  const applyToBeNode = async () => {
    setBtn1(true);
    try {
      const receipt = await (await nodeSign.applyToBeNode()).wait();
      if (receipt.status === 1) {
        message.success(t("benode"));
        setBtn1(false);
        window.location.reload();
      }
    } catch (e) {
      setBtn1(false);
      message.error(t("applynode") + t("fail"));
      message.error(e.reason || e.data?.message || e.message);
    }
  };

  async function getLists() {
    let relation = new ethers.Contract(relation_addr, relation_Abi, provider);
    await relation.getSubordinates(address).then(async (res) => {
      let sum = [];

      for (let i = 1; i <= res.length; i++) {
        sum[i - 1] = res[res.length - i];
      }

      setList(sum);
    });
    relation.getSubNum(address).then((res) => {
      setSubNum(parseInt(res));
    });
  }

  // const copyInvote = () => {
  //   if (address == "") {
  //     message.info(t("conn"));
  //     return;
  //   }
  //   copy(window.location.href.split("?", 1).toString() + "?inviter=" + address);
  //   message.success(t("copys"));
  // };

  const presaleData = () => {
    perSell.getPreSellLpData(address).then((res) => {
      setPerSellData([
        (ethers.utils.formatEther(res[0]) * 1).toFixed(4),
        (ethers.utils.formatEther(res[1]) * 1).toFixed(4),
      ]);
    });
    lpdata.getUserDynamic(address).then((res) => {
      setComputeNum((ethers.utils.formatEther(res) * 1).toFixed(4));
    });
  };

  useEffect(() => {
    if (address === "") return;
    getuserInfo();
    getNodeInfo();
    getLists();
    presaleData();
  }, [address]);

  return (
    <>
      <div className="home">
        <div className={styles.invitetop}>
          <img src="./img/invite/invite.png" alt="top" />
        </div>
        <div className={styles.burn0}></div>
        <div className={styles.topbar}>
          <div className={styles.flex_b}>
            <div className={styles.g4_5}>
              <div>{t("validdirectperson")}</div>
              <div className={styles.fontim}>{subNum}</div>
            </div>
            <div className={styles.g4_5}>
              <div>{t("teamtotalperformance")}</div>
              <div className={styles.fontim}>{userinfo.total}</div>
            </div>
          </div>
          <div className={styles.flex_b}>
            <div className={styles.g4_5}>
              <div>{t("directpushtotalformance")}</div>
              <div className={styles.fontim}>{userinfo.amount}</div>
            </div>
            <div className={styles.g4_5}>
              <div>{t("dynamic")}</div>
              <div className={styles.fontim}>{computeNum}</div>
            </div>
            {/* <div className={styles.g4_5}>
              <div>
                {t("node")}
                {isNode ? (
                  ""
                ) : (
                  <>
                    (<span className="red">{t("invalidnode")}</span>)
                  </>
                )}
              </div>
              {isNode ? (
                <span className={styles.fontim}>{t("primarynode")}</span>
              ) : (
                <Button
                  className={styles.nodebtn}
                  onClick={applyToBeNode}
                  loading={btn1}
                >
                  {t("applynode")}
                </Button>
              )}
            </div> */}
          </div>
          <div className={styles.flex_b}>
            <div className={styles.g4_5}>
              <div>{t("myprelpcompute")}</div>
              <div className={styles.fontim}>{perSellData[1]}</div>
            </div>
            <div className={styles.g4_5}>
              <div>{t("myaddlpamount")}</div>
              <div className={styles.fontim}>{perSellData[0]}U</div>
            </div>
          </div>
          {/* <div className={styles.flex_b}>
            <div className={styles.g4_5}>
              <div>{t("nodeincome")}(MM)</div>
              <div className={styles.fontim}>{pendNum}</div>
            </div>
            <div className={styles.g4_5}>
              {!isNode && (
                <Button
                  className={styles.getbtn}
                  onClick={withdraw}
                  loading={btn}
                >
                  {t("extract")}
                </Button>
              )}
            </div>
          </div> */}
        </div>
        <div className={styles.burn}></div>
        <div className={styles.burn2}></div>
        <div className={styles.center}>
          <div className={styles.invite}>
            {/* <div className={styles.inviteburn}></div> */}
            <div className={styles.invbar}>
              <InvBar provider={provider} inviter={inviter} />
            </div>
            <div className={styles.burn3}></div>
            <div className={styles.burn4}></div>
            <div className={styles.invh1}>{t("mydirect")}</div>

            <div className={styles.invbottom}>
              <div className={cm(styles.invbtop)}>{t("directaddress")}</div>

              {list.length > 0 ? (
                <>
                  {list.map((item, index) => {
                    return (
                      <div className={styles.invbbom} key={index}>
                        <div className={styles.op6}>
                          <div>{index + 1}、 </div>
                          <div>{item}</div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <div className={styles.noimg}>
                    <img src="./img/invite/no.png" alt="" loading="lazy" />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
