import { atom } from "recoil";

export const addressState = atom({
  key: "address",
  default: "",
});

export const pageState = atom({
  key: "page",
  default: 0,
});
